@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Rubik', sans-serif !important;
  height: 'fit-content';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  pointer-events: none;
}
.App {
  font-family: 'Rubik', sans-serif !important;
  text-align: center;
}

.App, #root, body {
  height: 100%;
}

html {
  height: 110%;
}

.agencyData {
  /* display: flex; */
  flex: 1;
  /* flex-wrap: wrap; */
  /* gap: 30px; */
}

.agencyDataBox {
  display: flex;
  flex : 1;
  min-width: fit-content;
}

.agencyDataBoxTitle {
  font-weight: bold;
  margin-right: 10px;
}

.drawerLink {
  text-decoration: none;
  color: #222222;
}


.errorMessage {
  white-space: pre-line;
}


.footer  {
  background-color:  #FEFFFE;
  padding: 10px;
  margin-top:calc(5% - 10);
  bottom: 0;
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
  color: #3f51b5;
  background-color: rgb(234, 236, 247) !important
}

.theme-switch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px
}