@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,800&display=swap);
body {
  margin: 0;
  font-family: 'Rubik', sans-serif !important;
  height: 'fit-content';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  pointer-events: none;
}
.App {
  font-family: 'Rubik', sans-serif !important;
  text-align: center;
}

.App, #root, body {
  height: 100%;
}

html {
  height: 110%;
}

.agencyData {
  /* display: flex; */
  flex: 1 1;
  /* flex-wrap: wrap; */
  /* gap: 30px; */
}

.agencyDataBox {
  display: flex;
  flex : 1 1;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.agencyDataBoxTitle {
  font-weight: bold;
  margin-right: 10px;
}

.drawerLink {
  text-decoration: none;
  color: #222222;
}


.errorMessage {
  white-space: pre-line;
}


.footer  {
  background-color:  #FEFFFE;
  padding: 10px;
  margin-top:calc(5% - 10);
  bottom: 0;
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
  color: #3f51b5;
  background-color: rgb(234, 236, 247) !important
}

.theme-switch {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: 10px
}
.form {
    max-width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    grid-gap : 20px;
    gap : 20px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .form .form__custom-button {
    flex : 1 1;
    width: 20%;
    padding :10px;
    margin: 0 auto;
  }
  
  .customInput {
    flex : 1 1
  }


.formLogo {
    width: 250px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
}


body {
  background-color:  #FEFFFE !important;
  text-align: center;
}
