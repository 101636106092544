.form {
    max-width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    gap : 20px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .form .form__custom-button {
    flex : 1;
    width: 20%;
    padding :10px;
    margin: 0 auto;
  }
  
  .customInput {
    flex : 1
  }


.formLogo {
    width: 250px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
}


body {
  background-color:  #FEFFFE !important;
  text-align: center;
}